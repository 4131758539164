import Head from 'next/head'

const SEO = ({
    description = "Weedzly is your trusted resource for everything cannabis. Find deals and discounts and start saving money at the dispensary.",
    image = '/static/icons/favicon.png',
    title = 'Weedzly - Find Cannabis Deals & Discounts Near You',
    url, 
}) => {


    return (
        <Head>
                <title>{title}</title>
                <meta name="description" content={description} key='description'/>

                <link rel="icon" type="image/png" href='/static/icons/favicon.png'></link> 
                <meta property="og:title" content={title} key='ogTitle'></meta>
                <meta property="og:description" content={description} key='ogDescription'></meta>
                <meta property="og:image" content={image ? image : '/static/icons/favicon.png'} key='ogImage'/>
                <meta property="og:url" content={url} key='ogUrl'/>
                <meta property="og:site_name" content={'Weedzly'} key='ogSiteName'/>
                <meta property="og:type" content="website" key='ogType'/>

                <meta name="twitter:card" content={'summary'} key='ogTwitterCard'/>
                <meta name="twitter:title" content={title} key='ogTwitterTitle'/>
                <meta name="twitter:description" content={description} key='ogTwitterDescription'/>
                <meta name="twitter:image" content={image ? image : '/static/icons/favicon.png'} key='ogTwitterImage'/>

        </Head>
    )

}

export default SEO