import queryString from 'query-string'
import { useCookie } from 'next-cookie'
import { BRANDS_IN_SUBZONE, DELIVERY_IN_SUBZONE, DISPENSARY_IN_SUBZONE, DISPENSARY_VIEW, MY_LOCATION_PLACE } from '../helpers/constTypes'
import { sponsor_subzoneService } from '../services/sponsor_subzone.service'
import cookie from 'js-cookie'




export function locationStringifyFromCookies(context, options){
    const location = useCookie(context).cookie.cookies.myLocation
    const productScanRadius = useCookie(context).cookie.cookies.productScanRadius
    const queryBoundingRadius = context.query.boundingRadius

    let data;
    if(location){
        const { latitude, longitude, boundingRadius } = JSON.parse(location)
        const query = {
            latitude: context.query.latitude && context.query.longitude ? context.query.latitude : latitude,
            longitude: context.query.latitude && context.query.longitude ? context.query.longitude : longitude,
            boundingRadius: productScanRadius ? productScanRadius : queryBoundingRadius ? queryBoundingRadius : boundingRadius
        }
        if(options && options.object){
            data = {
                latitude,
                longitude
            }
        } else {
            data = `${queryString.stringify(query, {skipEmptyString: true})}`
        }
        
    } else {
        data = ''
    }
    return data
}

export function getBusinessFeaturedInModule(feature, type){

    if(type){
        if(type === 'dispensary'){
            let _dispensary = cookie.get(DISPENSARY_IN_SUBZONE)
            if(_dispensary && JSON.parse(_dispensary) && JSON.parse(_dispensary).subscriptionFeatures){
                if(JSON.parse(_dispensary).subscriptionFeatures[feature]){
                    return JSON.parse(_dispensary)
                }
            }
            return false
        } else {
            let _delivery = cookie.get(DELIVERY_IN_SUBZONE)
            
            if(_delivery && JSON.parse(_delivery) && JSON.parse(_delivery).subscriptionFeatures){
                if(JSON.parse(_delivery).subscriptionFeatures[feature]){
                    return JSON.parse(_delivery)
                }
            }
            return false
        }
    } else {
        let _dispensary = cookie.get(DISPENSARY_IN_SUBZONE)
        if(_dispensary && JSON.parse(_dispensary) && JSON.parse(_dispensary).subscriptionFeatures){
            if(JSON.parse(_dispensary).subscriptionFeatures[feature]){
                return JSON.parse(_dispensary)
            }
        }
    
        let _delivery = cookie.get(DELIVERY_IN_SUBZONE)
        if(_delivery && JSON.parse(_delivery) && JSON.parse(_delivery).subscriptionFeatures){
            if(JSON.parse(_delivery).subscriptionFeatures[feature]){
                return JSON.parse(_delivery)
            }
        }
    
        return false
    }
    

    

    

    

}

// export async function getBusinessSubzone(longitude, latitude, cookie){
//     try {

//         let sponsor_subzone = await sponsor_subzoneService.findByLocation(longitude, latitude)
       
//         if(Object.keys(sponsor_subzone).length > 0){
//             if(sponsor_subzone.brand && sponsor_subzone.brand.length > 0){
//                 await cookie.set(BRANDS_IN_SUBZONE, JSON.stringify(sponsor_subzone.brand), { expires: 1, path: '/' });
//             }
//             if(sponsor_subzone.dispensary && Object.keys(sponsor_subzone.dispensary).length > 0){
//                 console.log(sponsor_subzone.dispensary)
//                 await cookie.set(DISPENSARY_IN_SUBZONE, JSON.stringify(sponsor_subzone.dispensary), { expires: 1, path: '/' });
//             }
//             if(sponsor_subzone.delivery && Object.keys(sponsor_subzone.delivery).length > 0){
//                 await cookie.set(DELIVERY_IN_SUBZONE, JSON.stringify(sponsor_subzone.delivery), { expires: 1, path: '/' });
//             }
//         } else {
//             await cookie.remove(BRANDS_IN_SUBZONE, { path: '/' })
//             await cookie.remove(DISPENSARY_IN_SUBZONE, { path: '/' })
//             await cookie.remove(DELIVERY_IN_SUBZONE, { path: '/' })
//             // await cookie.remove(DOCTOR_IN_SUBZONE, { path: '/' })
//         }

//         return true

//     } catch (er) {

//         await cookie.remove(BRANDS_IN_SUBZONE, { path: '/' })
//         await cookie.remove(DISPENSARY_IN_SUBZONE, { path: '/' })
//         await cookie.remove(DELIVERY_IN_SUBZONE, { path: '/' })
//         // await cookie.remove(DOCTOR_IN_SUBZONE, { path: '/' })
//         return false

//     }
// }

export async function getBusinessSubzone(longitude, latitude, cookie){
    try {
        
        await cookie.remove(BRANDS_IN_SUBZONE)
        await cookie.remove(DISPENSARY_IN_SUBZONE)
        await cookie.remove(DELIVERY_IN_SUBZONE)
        
        let sponsor_subzone = await sponsor_subzoneService.findByLocation(longitude, latitude)
        if(Object.keys(sponsor_subzone).length > 0){
            if(sponsor_subzone.brand && sponsor_subzone.brand.length > 0){
                await cookie.set(BRANDS_IN_SUBZONE, JSON.stringify(sponsor_subzone.brand), {expires: 1, path: '/' });
            } 
            if(sponsor_subzone.dispensary && Object.keys(sponsor_subzone.dispensary).length > 0){
                await cookie.set(DISPENSARY_IN_SUBZONE, JSON.stringify(sponsor_subzone.dispensary), {expires: 1, path: '/' });
            } 
            if(sponsor_subzone.delivery && Object.keys(sponsor_subzone.delivery).length > 0){
                await cookie.set(DELIVERY_IN_SUBZONE, JSON.stringify(sponsor_subzone.delivery), {expires: 1, path: '/' });
            } 
            
            if(sponsor_subzone.state && Object.keys(sponsor_subzone.state).length > 0){
                await cookie.set(MY_LOCATION_PLACE, JSON.stringify({state:sponsor_subzone.state}), {expires: 1, path: '/' })
                await cookie.set('IS_CANNABIS_LEGAL', JSON.stringify(sponsor_subzone.state.isCannabisLegal), {expires: 1, path: '/' })
                await cookie.set('IS_DELIVERY_AVAILABLE', JSON.stringify(sponsor_subzone.state.isDeliveryAvailable),{expires: 1, path: '/' })
            }
        }
        await cookie.set('SPONSORED_SCANNED', '1', {expires: 1, path: '/' });
        return true

    } catch (er) {

        return false

    }
}





export function visitedArticlesFromCookies(context){
    const data = JSON.parse(useCookie(context).cookie.cookies[DISPENSARY_VIEW])
    return data
}

export function getUserTokenFromCookies(context){
    const string = useCookie(context).cookie.cookies['user']
    if(string){
        const data = JSON.parse(string)
        return data.token
    } else {
        return null
    }
    
}

export function getUserIdFromCookies(context){
    const string = useCookie(context).cookie.cookies['user']
    if(string){
        const data = JSON.parse(string)
        return data._id
    } else {
        return null
    }
    
}
