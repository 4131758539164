export const MY_LOCATION_PLACE_NAME = 'myLocationPlaceName';
export const MY_LOCATION = 'myLocation';
export const DISPENSARY_VIEW = 'dispensaryview'
export const ARTICLE_VIEW = 'articleview'
export const ACCEPT_TERMS = 'acceptedTerms'
export const ENABLE_GEOLOCATION = 'enableGeolocation'
export const ACCEPT_COOKIES = 'acceptCookies'
export const MY_LOCATION_PLACE= 'myLocationPlace'
export const LOCAL_CART = 'localCart'
export const ENTITY_CART = 'entityCart'
export const BACKGROUND_STATE = 'backgroundState'
export const BUSINESS_IN_SUBZONE = 'business_in_zone'
export const DISABLE_GEOLOCATION = 'disableGeolocation'
export const SUBZONE_OWNER = 'subzone_owner'

export const BRANDS_IN_SUBZONE = 'BRANDS_IN_SUBZONE'
export const DISPENSARY_IN_SUBZONE = 'DISPENSARY_IN_SUBZONE'
export const DELIVERY_IN_SUBZONE = 'DELIVERY_IN_SUBZONE'
// export const DOCTOR_IN_SUBZONE = 'DOCTOR_IN_SUBZONE'
