import { useCookie } from 'next-cookie'
import { BACKGROUND_STATE, MY_LOCATION, MY_LOCATION_PLACE, MY_LOCATION_PLACE_NAME, BRANDS_IN_SUBZONE, DISPENSARY_IN_SUBZONE, DELIVERY_IN_SUBZONE } from '../helpers/constTypes';
import { getBusinessSubzone, getBusinessSubzonea, getCityId } from '../helpers/location';
import { mapboxService } from '../services/mapbox.service';
import { stateService } from '../services/state.service';



// export const serverLocationDetector = async (context) => {
//     let cookie = useCookie(context)
//     if(cookie.get(MY_LOCATION)){
//         return cookie.get(MY_LOCATION)
//     } else if(context.query.ip_longitude && context.query.ip_latitude) {
//         return {
//             'longitude': context.query.ip_longitude,
//             'latitude': context.query.ip_latitude,
//             'region': context.query.ip_region, 
//             'city': context.query.ip_city,
//             'country': context.query.ip_country, 
//             'boundingRadius':80467
//         }
//     } else {
//         return {
//             'longitude': '-118.24368',
//             'latitude': '34.05223',
//             'region': 'CA', 
//             'city': 'Los Angeles',
//             'country': 'US', 
//             'boundingRadius':80467
//         }
//     }
    
// }

var crawlerAgentRegex = /bot|prerender|google|aolbuild|baidu|bing|msn|duckduckgo|teoma|slurp|petal|yandex/i;

export const serverLocationDetector = async (context) => {
    
    let cookie = useCookie(context)


    let locationCookies = cookie.get('myLocation') && cookie.get('myLocation').type != 'IP'  ? cookie.get('myLocation') : null
    let currentLocation = {
        longitude: -118.24368,
        latitude: 34.05223,
        boundingRadius: 80467,
        type:'IP'
    }
    let label = 'Los Angeles, California'

    if (crawlerAgentRegex.test(context.req.headers['user-agent'])){
        console.log(`IT'S A BOT`)
        await cookie.set(MY_LOCATION_PLACE_NAME, 'Los Angeles, California 90012, United States', { path: '/' });
        await cookie.set('REGION', 'California', { path: '/' });
        await cookie.set('MY_LOCATION_PLACE_FULLNAME', 'Ichiban of Tokyo, 201 N Los Angeles St, Los Angeles, California 90012, United States', { path: '/' });
        await cookie.set(MY_LOCATION, JSON.stringify({
            longitude: currentLocation.longitude, 
            latitude: currentLocation.latitude,
            zoom:8,
            boundingRadius:80467,
            type: 'IP'
        }), { path: '/' })
        return currentLocation
    } else if(locationCookies 
        // && JSON.parse(locationCookies).type != 'IP'
        ){
      
        currentLocation.longitude = locationCookies.longitude
        currentLocation.latitude = locationCookies.latitude
        currentLocation.boundingRadius = locationCookies.boundingRadius
    } else {

        await cookie.set('IS_DELIVERY_AVAILABLE', 'true', { path: '/' });
        await cookie.set('IS_CANNABIS_LEGAL', 'true', { path: '/' }); 
        await cookie.set(MY_LOCATION_PLACE_NAME, 'Los Angeles, California 90012, United States', { path: '/' });
        await cookie.set('REGION', 'California', { path: '/' });
        await cookie.set('MY_LOCATION_PLACE_FULLNAME', 'Ichiban of Tokyo, 201 N Los Angeles St, Los Angeles, California 90012, United States', { path: '/' });
        await cookie.set(MY_LOCATION, JSON.stringify({
            longitude: currentLocation.longitude, 
            latitude: currentLocation.latitude,
            zoom:8,
            boundingRadius:80467,
            type: 'IP'
        }), { path: '/' })
        return currentLocation

        // let ip = false
        // if (context.req.headers["x-forwarded-for"]) {
        //     ip = context.req.headers["x-forwarded-for"].split(',')[0]
        //   } else if (context.req.headers["x-real-ip"]) {
        //     ip = context.req.connection.remoteAddress
        //   } else {
        //     ip = context.req.connection.remoteAddress
        //   }
        // const ipLocation = await getLocationByIp(ip)
    
    
        // if(isFinite(ipLocation.longitude) && Math.abs(ipLocation.longitude) && isFinite(ipLocation.latitude) && Math.abs(ipLocation.latitude)){
        //     currentLocation.longitude = ipLocation.longitude
        //     currentLocation.latitude = ipLocation.latitude
        //     label = ipLocation.city +', '+ ipLocation.subdivision
        // }
      
       
        // await updateLocationCookies(currentLocation, useCookie(context), true, 'IP')
    }
    
    
    if(!cookie.get('SPONSORED_SCANNED')){

        await getBusinessSubzone(currentLocation.longitude, currentLocation.latitude, cookie)
    }
    return currentLocation
}




export const clientLocationDetector = async (cookie, reload) => {

 
    if ( navigator.permissions && navigator.permissions.query) {
        return navigator.permissions.query({name:'geolocation'})
        .then(async result => {
            if (result.state == 'granted') {
                navigator.geolocation.getCurrentPosition( async(position) => {
                
                    await updateLocationCookies(position.coords, cookie)
                    reload()
                
                });
            } else if (result.state == 'prompt') {
                navigator.geolocation.getCurrentPosition(async (position) => {
                    await updateLocationCookies(position.coords, cookie)
                    reload()
                });
            } else {
                if(!cookie.get(MY_LOCATION)){
                    await updateLocationCookies(await getLocationByIp(), cookie)
                    reload()
                } 
            }
        })
    } else if (navigator.geolocation) {
        return navigator.geolocation.getCurrentPosition(async(position) => {
            await updateLocationCookies(position.coords, cookie)
            reload()
        });
    } else {
        console.log('Geolocation not available')
    }
    
}





const updateLocationCookies = async (data, cookie, dontGetZone, type) => {
    try {
        let lastMyLocation = cookie.get(MY_LOCATION)
        if(data.longitude && data.latitude){
            if(!cookie.get(MY_LOCATION) || (data.longitude != lastMyLocation.longitude && data.latitude != lastMyLocation.latitude) ){
                let res = await mapboxService.reverseGeocoding(data.longitude, data.latitude, 'types='+encodeURIComponent(['address', 'place', 'region', 'postcode']))
                // let cityInfo = await getCityId(res.features.find( x => x.id.includes('place')))
                let stateInfo = await getStateId(res.features.find( x => x.id.includes('region')).properties.short_code.replace('US-', '')) 
                await cookie.set(BACKGROUND_STATE, stateInfo.background, { path: '/' })
                let myLocationPlace = {
                    place: res.features.find( x => x.id.includes('place')) && res.features.find( x => x.id.includes('place')).text,
                    region: res.features.find( x => x.id.includes('region')) && res.features.find( x => x.id.includes('region')).text,
                    center: res.features.find( x => x.id.includes('place')) && res.features.find( x => x.id.includes('place')).center,
                    stateId: stateInfo._id,
                    // cityId: cityInfo._id,
                    state:{
                        _id: stateInfo._id,
                        isDeliveryAvailable: stateInfo.isDeliveryAvailable,
                        isCannabisLegal: stateInfo.isCannabisLegal
                    }
                }
                
                let state = res.features.find( x => x.id.includes('region')).text
                let zip = res.features.find( x => x.id.includes('postcode'))
                let secondText = res.features.find( x => x.id.includes('region')).context[0].short_code
                if(zip){
                    secondText = zip.text.toUpperCase()
                }

                let city = res.features.find( x => x.id.includes('place'))
                if(city){
                    state = city.text
                }

                let locationPlaceName = state + ', ' + secondText
                // let locationPlaceName = res.features.find( x => x.id.includes('address'))
                //     ?   res.features.find( x => x.id.includes('address'))
                //     :   res.features.find( x => x.id.includes('postcode'))
                //     ?   res.features.find( x => x.id.includes('postcode'))
                //     :   res.features.find( x => x.id.includes('place'))
                //     ?   res.features.find( x => x.id.includes('place'))
                //     :   res.features.find( x => x.id.includes('region'))

                if(dontGetZone){

                } else {
                    getBusinessSubzone(data.longitude, data.latitude, cookie)
                }
              
                await cookie.set(MY_LOCATION_PLACE_NAME, locationPlaceName, { path: '/' });


               
                await cookie.set('REGION', res.features.find( x => x.id.includes('region')).text, { path: '/' });
              
                await cookie.set('MY_LOCATION_PLACE_FULLNAME', res.features[0].place_name, { path: '/' });
                await cookie.set(MY_LOCATION, JSON.stringify({
                    longitude: data.longitude, 
                    latitude: data.latitude,
                    zoom:8,
                    boundingRadius:80467,
                    type
                }), { path: '/' })
                await cookie.set(MY_LOCATION_PLACE, JSON.stringify(myLocationPlace), { path: '/' });
            }
        }
    } catch(er) {
        console.log(er)
    }
};


const getLocationByIp = async (ip='') => {
    let response = await fetch('https://www.iplocate.io/api/lookup/'+ip+'?apikey=d7e6797fb909933d8d3c9cab7dc0620c')
    //key d7e6797fb909933d8d3c9cab7dc0620c
    let parsedData = await response.json()
    return parsedData
};

const getStateId = async (uuid) => {
    try {
        let state = await stateService.findByAbr(uuid)
        return state
    } catch {
        return false
    }
}


export const mapUrlGetter = (type, cookie) => {
    return createMapUrl(type, cookie)
    
}

let createMapUrl = (type, cookie) => {
    let location_place = cookie.get(MY_LOCATION_PLACE)

    if(!location_place){

        return "/dispensaries"

    } else {

        const { place, region } = JSON.parse(location_place)

        switch (type) {
            case 'href':
                if(!place){
                    return "/map/[region]" 
                } else {
                    return "/map/[region]/[place]" 
                }
            case 'as':
                if(!place){
                    return `/map/${encodeURIComponent(region.toLowerCase().replace(' ', '-'))}`
                } else {
                    return `/map/${encodeURIComponent(region.toLowerCase().replace(' ', '-'))}/${encodeURIComponent(place.toLowerCase().replace(' ', '-'))}`
                }
            default:
                break;
        }

    }
}

export const reloadLocation = (path, router, cookie) => {
  
    if(window.location.search.length > 0){
        window.location = window.location.pathname+window.location.search
    } else {
        window.location.reload()
    }
        
    
}

export const getAds = () => {
    try {

    } catch (er){
        
    }
}