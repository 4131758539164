import React, { useEffect } from 'react';
import Head from "next/head";
import CircleSpinner from '../LoadingAnimation/CircleSpinner'
import { useDispatch, useSelector } from 'react-redux'
import { checkUserLogin, getFavorites, pageOpened, setSiteWideAds, setSiteWideDeal } from '../../store/actions/cartActions';
import { dealService, dispensaryService, favoriteService } from '../../services';
import cookie from 'js-cookie'
import SEO from './SEO';
import { ShallowPaginationProvider } from '../../utils/useShallowPageController';
import ModalsController from '../Layouts/ModalsController';
import { getBusinessFeaturedInModule } from '../../helpers/location';
import Cookies from 'js-cookie';
import { MY_LOCATION } from '../../helpers/constTypes';

const Layout = ({seo, children, excludeFooter, isAppleMobile, showLoader, noIndex}) => {
 
    const dispatch = useDispatch()
    
    const fetchFavorites = async () => {
        try {
            return await favoriteService.getAll()
        } catch (err){
            console.log(err)
        }

    }

    const fetchSponsoredDeal = async () => {
        let selectedDeal
        if(getBusinessFeaturedInModule('showADealOnSiteWideBanner')){
            let owner = getBusinessFeaturedInModule('showADealOnSiteWideBanner')
            let deals = await dealService.getByDispensary(owner.slug, '')
            if(deals.totalData.length > 0){
                selectedDeal = deals.totalData[Math.floor(Math.random() * deals.totalData.length)]
                await dispatch(setSiteWideDeal({...selectedDeal, ...{dispensary: owner}}))
            }
        } 
    }
    
    const openSite = async () => {
        if(!state.pageOpened){
            await Promise.all([
                dispatch(checkUserLogin()),
                dispatch(pageOpened())
            ])
            getAds()
            localStorage.removeItem('NoSponsoredDeal')
            setTimeout(() => {
                setLoader(false)
            }, 10);

            setTimeout(() => {
                fetchSponsoredDeal()
            }, 30000);
          
            if(cookie.get('user')){ 
                await dispatch(getFavorites(await fetchFavorites()))
            }
        } else {
            setLoader(false)
        }
    }

    const state = useSelector(state => state)

    const [loader, setLoader] = React.useState(true);

    React.useEffect(() => {
        openSite()
    }, [])




    const getAds = async () => {
        try {
           
            const location = Cookies.get(MY_LOCATION)
            const { latitude, longitude} = JSON.parse(location)
            let response = await dispensaryService.getAds(`?latitude=${latitude}&longitude=${longitude}`)
            dispatch(setSiteWideAds(response))
        } catch (er) {
            console.log(er);
        }
    }


    

    return(<>
            <Head>
                <>
                    {
                        isAppleMobile 
                            ?   <meta name="viewport" content="width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=1.0, shrink-to-fit=no"/>
                            :   <meta name="viewport" content="width=device-width, initial-scale=1, minimum-scale=1, shrink-to-fit=no"/>
                    
                    }
                    {
                        noIndex 
                            ?   <meta name='robots' content='noindex' /> 
                            :   ''
                    }
                </>
                <meta name="apple-itunes-app" content="app-id=6447017467"></meta>
            </Head>
          
            <SEO
              {...seo}
            />     
            {
                !loader ? <ShallowPaginationProvider>
                    <ModalsController embeeded={false} />
                    {children}
                    </ShallowPaginationProvider> : (
                        showLoader ? 
                        <CircleSpinner fullScreen={true} /> : ''
                
                  
                )
            }
            {/* {
                !excludeFooter && <Footer />
            } */}
            
        </>
    );
}

export default Layout;