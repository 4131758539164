import queryString from 'query-string'
import isEqual from 'is-equal';
import isEmpty from './isEmpty'

const checkArray = (data) => {
    if(!Array.isArray(data)) return [data]
    else return data
}

export const parse = (contextQuery) => {
    
    let filterfield = contextQuery['filterfield[]'] ? checkArray(contextQuery['filterfield[]']) : contextQuery['filterfield'] ? checkArray(contextQuery['filterfield']) : null;
    let filtertype = contextQuery['filtertype[]'] ? checkArray(contextQuery['filtertype[]']) : contextQuery['filtertype'] ? checkArray(contextQuery['filtertype']) : null;
    let filtervalue = contextQuery['filtervalue[]'] ? checkArray(contextQuery['filtervalue[]']) : contextQuery['filtervalue'] ? checkArray(contextQuery['filtervalue']) : null;

     
    if(filterfield && filterfield.length>0){
        let filters = filterfield.map((x, i) => {
            return {
                filterfield : filterfield[i],
                filtertype : filtertype[i],
                filtervalue : Array.isArray(filtervalue[i]) ? [filtervalue[i]] : filtervalue[i],
                // filterlabel : filtervalue[i].replace('-', ' ')
            }
        })
        if(contextQuery['filterfield[]']) delete contextQuery['filterfield[]']
        if(contextQuery['filtertype[]']) delete contextQuery['filtertype[]']
        if(contextQuery['filtervalue[]']) delete contextQuery['filtervalue[]']
        return {filters:filters, ...contextQuery}
    } else {
        return {filters:[], ...contextQuery}
    }
}

export const stringify = (newFilter) => {

    if(newFilter){
        let filterfield = []
        let filtertype  = []
        let filtervalue = []
        Object.keys(newFilter).map( x => {
            filterfield.push(newFilter[x].filterfield)
            filtertype.push(newFilter[x].filtertype)
            filtervalue.push(newFilter[x].filtervalue)
        })
        return '&'+queryString.stringify({filterfield, filtertype, filtervalue}, {arrayFormat: 'bracket'})
    } else {
        return ''
    }
}

export const filterHandler = (filterParam, currentQuery) => {

    let filterfield = []
    let filtertype  = []
    let filtervalue = []

    let exists = false
    let newFilters = []
  
    let currentFilterQuery = parse(currentQuery)
   
    let filters = currentFilterQuery.filters

  
    if(isEmpty(filterParam) && isEmpty(currentFilterQuery)){
 
        return ('')
    }

    if(isEmpty(filterParam)){
    
        filterfield = currentQuery.filterfield
        filtertype = currentQuery.filtertype
        filtervalue = currentQuery.filtervalue

    } else {
        let newFilter = {
            filtertype: filterParam.filtertype,
            filtervalue: filterParam.filtervalue,
            filterfield: filterParam.filterfield,
        }

        filters.map((x, i) => {
            if(isEqual(newFilter, x)){
                exists = true
            } 
        })

        if(!filterParam.multi){
            filters = filters.filter( x => x.filterfield!=filterParam.filterfield)
        }
        
    
        if(exists){
            newFilters = filters.filter(x=> !isEqual(x, newFilter))
        }
        
        if(!exists){
            if(newFilter.filtertype==="range" && Array.isArray(newFilter.filtervalue)){
                newFilters = filters.concat(newFilter.filtervalue.map( (x, i)=> {
                    return{
                        filterfield: newFilter.filterfield,
                        filtertype: 
                            i===0 && newFilter.filtervalue[0] > newFilter.filtervalue[1] ? 'lte' 
                            : i===0 && newFilter.filtervalue[1] > newFilter.filtervalue[0] ? 'gte' 
                            : i===1 && newFilter.filtervalue[0] > newFilter.filtervalue[1] ? 'gte' 
                            : i===1 && newFilter.filtervalue[1] > newFilter.filtervalue[0] ? 'lte' 
                            
                            : '',
                        filtervalue: x
                    }
                }))
            } else {
                newFilters = filters.concat(newFilter)
            }
        }
    
        newFilters.map( x => {
            filterfield.push(x.filterfield)
            filtertype.push(x.filtertype)
            filtervalue.push(x.filtervalue)
        })

    }

    
    
    return (
        queryString.stringify({filterfield, filtertype, filtervalue}, {arrayFormat:'bracket', skipEmptyString: true})
    )
}





export const mainOptionsHandler = (page, size, moreParams, searchQuery) => {

    let newSearchQuery={
        sortField: searchQuery.sortField || '',
        sortOrder: searchQuery.sortOrder || '',
        search: searchQuery.search || '',
        size: searchQuery.size || '',
        page: searchQuery.page || '',
    }
    let newParams = queryString.stringify({...newSearchQuery, page: page ? page+1 : '', size:size || '', ...moreParams}, {arrayFormat:'bracket', skipEmptyString: true})
    return (
        newParams ? newParams : ''
    )

}


export const searchQueryHandler = (page, size, query, newFilter, searchQuery, extraOpts, resetFields) => {
   
    let filter = ''

    let newSearchQuery = searchQuery

    
    
    if(resetFields){
        let currentFilterQuery = parse(searchQuery)
   
        let filters = currentFilterQuery.filters
    
        let newCurrentFilterQuery = filters.filter(x => !resetFields.includes(x.filterfield))
    
        newSearchQuery = stringify(newCurrentFilterQuery)
    }

    

    if(isEmpty(newFilter) && resetFields){

        filter = newSearchQuery

    }
   
     else if(extraOpts && extraOpts.reset_apply_text){
        filter = '&'+extraOpts.filterText
    } else if(extraOpts && extraOpts.reset){
        
    } else {
        if(Array.isArray(newFilter)){
           
            filter = filter + newFilter.map(x => '&'+filterHandler(x, queryString.parse(newSearchQuery, {arrayFormat:'bracket'})))
            filter = filter.replaceAll(',', '')
        } else {
            filter = '&'+filterHandler(newFilter, searchQuery)
        }
    }

    if(filter === '&'){
        filter = null
    }
     
    let mainParams = mainOptionsHandler(page, size, query, searchQuery)
    let _query =  (mainParams && filter) 
        ? '?'+mainParams+filter : mainParams 
        ? '?'+mainParams : filter 
        ? '?'+filter : ''

    if(_query[1] === '&'){
        _query = '?'+_query.substr(2, _query.length)
    }
    return _query

}

export const detectActive = (value, field, type, currentFilters) => {

    let filterToCheck = {
        filterfield: field,
        filtertype: type,
        filtervalue: value
    }

    let check = currentFilters && currentFilters.find(x => isEqual(x, filterToCheck))
    if(check){
        return true
    } else {
        return false
    }
}

export const filterLister = (filters) => {

    let filterList = []

    
    filters.static.length > 0 && filters.static.map(x => {
        if(x.field!='range'){
            x.items && x.items.map(y => {
                filterList = filterList.concat({
                    filtertype: x.type,
                    multi: x.multi,
                    filtervalue: y.value,
                    filterfield: y.field,
                    filterlabel: y.label,
                })
            })
        } else {
            filterList = filterList.concat({
                multi: x.multi,
                filtertype: x.type,
                filterlabel: y.label,
                filterfield: y.field,
                filterlabel: y.label
            })
        }
    })

    return filterList
}