import { fetchWrapper } from '../helpers/fetch-wrapper';
import { getApi } from '../config';
const baseUrl = getApi+"sponsor_subareas";

export const sponsor_subzoneService = {
    findByLocation
};

function findByLocation(longitude, latitude) {
    return fetchWrapper.get(`${baseUrl}?longitude=${longitude}&latitude=${latitude}`);
}


